import React from 'react'
import { Layout } from '../components/layout'
import styles from './pages.module.css'
import { Helmet } from "react-helmet";


const Archive = () => {
    return (
        <Layout>
            <div className={styles.page}>
            <h1>Archive</h1>
                <div>Coming soon — you'll find all my work here.</div>
            </div>
        </Layout>
    )
}

export default Archive