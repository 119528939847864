import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './pages/home';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import DataInput from './pages/capture';
import Register from './pages/register';
import { useSelector } from 'react-redux';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Faq from './pages/faq';
import Subscribe from './pages/subscribe';
import Capture from './pages/capture';
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Newsletter from './pages/newsletterPage';
import Archive from './pages/archive';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './components/stripePayment';

const helmetContext = {};


export const PrivateRoutes = () => {
    const authState = useSelector((state) => state.auth);

    return (
        <>{authState.isAuth ? <Outlet /> : <Navigate to='/login' />}</>
    )
}
export const RestictedRoutes = () => {
    const authState = useSelector((state) => state.auth);

    return (
        <>{!authState.isAuth ? <Outlet /> : <Navigate to='/dashboard' />}</>
    )

}
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
    const options = {
        // passing the client secret obtained from the server
        // clientSecret: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    };
    return (
        // <Elements
        //     stripe={stripePromise}
        // // options={options}
        // >
            <HelmetProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route element={<PrivateRoutes />} >
                            <Route path='/dashboard' element={<Dashboard />} />
                            <Route path='/capture' element={<Capture />} />
                        </Route>
                        <Route element={<RestictedRoutes />} >
                            <Route path='/login' element={<Login />} />
                            <Route path='/register' element={<Register />} />
                        </Route>

                        <Route path='/home' element={<Home />} >
                        </Route>
                        <Route path='/privacy' element={<Privacy />} />
                        <Route path='/terms' element={<Terms />} />
                        <Route path='/newsletter' element={<Newsletter />} />
                        <Route path='/archive' element={<Archive />} />
                        {/* <Route path='/faq' element={<Faq />} /> */}
                        <Route path='/email-confirmation/:key' element={<Subscribe />} />
                    </Routes>
                </BrowserRouter>
            </HelmetProvider>
        // </Elements>
    );
}

export default App;
