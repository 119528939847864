import React from 'react'
import { Layout } from '../components/layout'
import styles from './pages.module.css'
import { Helmet } from "react-helmet";


const NewsletterPage = () => {
    return (
        <Layout>
            <div className={styles.page}>

                <h1>Science, Woo, Philosophy Newsletter</h1>
                <p>A free newsletter that delivers ideas from science, woo, and philosophy each week.</p>
                <p><a href='https://newsletter.stephenjurgensen.com/subscription/MSzNQzYhqw?locale=en-US'>Subscribe here</a>. 👈</p>
                <p>(And don't forget to check your spam or junk folder.)</p>
            </div>
        </Layout>
    )
}

export default NewsletterPage