import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './navbar.module.css';
import logov3 from '../img/logov3.png'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'



const Navbar = () => {
    // const authState = useSelector((state) => state.auth);
    const { isAuth } = useSelector((state) => state.auth)
    const [menuStyle, setMenuStyle] = useState({ display: "none" });
    const [coverEscapeStyle, setCoverEscapeStyle] = useState({ display: "none" });
    const [navMenuIconStyle, setNavMenuIconStyle] = useState({ zIndex: "1", backgroundColor: "white" });
    const [logoBoxBtnStyle, setLogoBoxBtnStyle] = useState({ zIndex: "1", backgroundColor: "white"});

    function handleClickMenu() {
        if (menuStyle.display == "none") {
            setMenuStyle({ display: "block" })
            setCoverEscapeStyle({ display: "block" })
            setNavMenuIconStyle({ zIndex: "3", backgroundColor: "#a8fccb" });
            setLogoBoxBtnStyle({ zIndex: "3", backgroundColor: "rgba(89, 89, 89, 0)"})

        } else {
            setMenuStyle({ display: "none" })
            setCoverEscapeStyle({ display: "none" })
            setNavMenuIconStyle({ zIndex: "3", backgroundColor: "white" })
            setLogoBoxBtnStyle({ zIndex: "3", backgroundColor: "white"})
        }
    }
    function handleCoverEscape() {
        if (coverEscapeStyle.display == "none") {
            setCoverEscapeStyle({ display: "block" })
            setMenuStyle({ display: "flex" })
            setNavMenuIconStyle({ zIndex: "3", backgroundColor: "#a8fccb" })
            setLogoBoxBtnStyle({ zIndex: "3", backgroundColor: "rgba(89, 89, 89, 0)"})
        } else {
            setCoverEscapeStyle({ display: "none" })
            setMenuStyle({ display: "none" })
            setNavMenuIconStyle({ zIndex: "3", backgroundColor: "white" });
            setLogoBoxBtnStyle({ zIndex: "3", backgroundColor: "white"})
        }
    }
    function handleHomeClick() {
        setCoverEscapeStyle({ display: "none" })
        setMenuStyle({ display: "none" })
        setNavMenuIconStyle({ zIndex: "3", backgroundColor: "white" });
        setLogoBoxBtnStyle({ zIndex: "3", backgroundColor: "white"})
    }

    return (
            <nav className={styles.navbar}>
                <div className={styles.menuCoverEscape} style={coverEscapeStyle} onClick={() => handleCoverEscape()}></div>
                <div className={styles.left}>
                    <div className={styles.logoBoxBtn} style={logoBoxBtnStyle}
                    onClick={() => handleHomeClick()}>
                    <Link to='/'>
                        <span className={styles.logo}>STEPHEN JURGENSEN</span>
                    </Link>
                    </div>
                </div>

                <div className={styles.right}>

                    <div
                        className={styles.menuBoxBtn}
                        onClick={() => handleClickMenu()}
                        style={navMenuIconStyle}
                    >
                        <div className={styles.navMenuIcon} ><FontAwesomeIcon icon={faBars} size="1.5x" /></div>
                        <div className={styles.navMenuText}>MENU</div>
                    </div>
                    <div className={styles.dropDownMenu} style={menuStyle} >
                        <Link to='/archive'>
                            <div className={styles.menuItem}>
                                Archive
                            </div>
                        </Link>
                        <Link to='/newsletter'>
                            <div className={styles.menuItem}>
                                Newsletter
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <div className={styles.menuBoxBtn} onClick={() => handleClickSearch()}>
                    <div className={styles.navSearchText} >SEARCH</div>
                    <div className={styles.navSearchIcon} ><FontAwesomeIcon icon={faSearch} size="2x" /></div>
                </div> */}
            </nav>
    )
}

export default Navbar



