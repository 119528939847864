import React, { useEffect, useState } from 'react'
import { Layout } from '../components/layout'
import styles from './pages.module.css'
import ReCAPTCHA from "react-google-recaptcha";
import { onRegistration } from '../apis/auth/auth';
import { onEmailSubscription } from '../apis/emailList/emailList';
import { Helmet } from "react-helmet-async";
import { PaymentElement } from '@stripe/react-stripe-js';
import { TestCheckoutForm } from '../components/stripePayment';


const Home = () => {
    const [values, setValues] = useState({ email: '', password: '' });
    const [email, setEmail] = useState({ email: '' });
    const [recaptcha, setRecaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error1, setError1] = useState(false);


    function onReCAPTCHA(value) {
        // console.log("Captcha value:", value);
        setRecaptcha(true);
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value });
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailSubmit = async (e) => {
        e.preventDefault()
        document.getElementById('email').value = '';
        document.getElementById('submit').disabled = 'true';
        try {
            const { data } = await onEmailSubscription(email)
            // const response = await onRegistration(values)
            // console.log(response);
            setError1('')
            setSuccess(data.message)
            setEmail('')
            setTimeout(() => {
                setSuccess(false)
            }, "5000");

        } catch (error) {
            // console.log(error);
            // console.log(error.response.data.errors[0].msg);
            if (error.response.data.errors[0].msg) {
                setError1(error.response.data.errors[0].msg)
                setTimeout(() => {
                    setError1(false)
                }, "5000");
            }
            // // if (error.response.data.errors[1].msg !== undefined) {
            //     setError1(error.response.data.errors[0].msg)
            // // }
            setSuccess('')
        }

    }
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute("content", "hello");
        console.log(document.querySelector('meta[name="description"]'))
    }, []);
    return (
        <Layout>
            <div className={styles.page}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Stephen Jurgensen</title>
                    <meta name="description" content="Home, The personal blog of Stephen Jurgensen. Art, philosophy, science, and more."></meta>
                </Helmet>
                {/* <TestCheckoutForm /> */}

                {/* <form onSubmit={(e) => onEmailSubmit(e)} id='register' className={styles.newsletter}>
                    <div className={styles.formInputDiv}>

                        <label htmlFor='email' className={styles.formNewsletterLabel}>
                            Sign up for my email newsletter:
                        </label>
                        <input
                            onChange={(e) => onEmailChange(e)}
                            type='email'
                            className={styles.newsletterInput}
                            id='email'
                            name='email'
                            value={email.email}
                            placeholder='Type your email'
                            required
                        />
                    </div>

                    <div className={styles.formInputRecaptcha}>

                        <ReCAPTCHA className={styles.recaptcha}
                            sitekey={process.env.NODE_ENV === 'development'
                                ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                : "6Ld000smAAAAADiSZ-7pXODcHNfZkMixqNpq0TFk"}
                            onChange={onReCAPTCHA}
                        />

                    </div>
                    <div className={styles.formInputSubmit}>

                        <button 
                            type='submit'
                            id='submit'
                            className={styles.submitBtn}
                            disabled={!recaptcha}

                        >
                            Submit
                        </button>

                        {success ? <div style={{ color: 'green', margin: '10px 0' }}>{success}</div> : ''}
                        {error1 ? <div style={{ color: 'red', margin: '10px 0' }}>{error1}</div> : ''}
                    </div>
                </form> */}
                <div id="preview-text">
                    <span id="preview-text-span">
                        Consider the relation of three ideas.
                    </span>
                </div>

                <div class="message-content">
                    <div class="header">
                        <div class="header-title">
                            Science, Woo, and Philosophy
                        </div>
                        <div class="header-subtitle">
                            Newsletter by Stephen Jurgensen
                        </div>
                    </div>
                    <div class="content-div" id='march22_2024'>
                        <h1>Science, Woo, and Philosophy: Thresholds, Out of Body, and Theory of Mind</h1>
                        <div class="content-div">
                            <span class="date">
                                March 15, 2024
                            </span>
                            <span class="separator">
                                |
                            </span>
                            <a class="author" href="https://stephenjurgensen.com" title="Stephen Jurgensen">
                                stephenjurgensen.com
                            </a>
                        </div>
                        <div class="content-div">
                            <p>Consider the following:</p>
                            <ul>
                                <li>
                                    Thresholds as the Bounds of Consciousness.
                                </li>
                                <li>
                                    A Brain Region that Produces Out-of-Body Experiences.
                                </li>
                                <li>
                                    Theory of Mind in Octopuses.
                                </li>
                            </ul>
                        </div>
                        <div class="content-div">
                            <h2 id="science"> Science: Thresholds </h2>
                        </div>
                        <p>
                            The bounds and varieties of human experience constitute the meaning of life. Precise description of our
                            experience can purify and straighten out our meaning, and, therefore, the indispensable field of
                            psychophysics offers us a ready hand.
                        </p>
                        <p>
                            <span id="foot-1-start"></span>Classical psychophysics developed “careful and precise means with which
                            to formulate exact, often mathematical statements about mental and physical events. In this sense,
                            classical psychophysics provides a model for all of psychology.” <a href="#foot-1"
                                class="in-text-foot"><sup class="superscript">1</sup></a>
                        </p>
                        <p>
                            <span id="foot-2-start"></span>Exact statements about sensory qualities entail the development of at
                            least two thresholds: the absolute
                            threshold of sensation and the just noticeable difference also known as a difference
                            threshold. In theory, by locating these thresholds on a hard physical scale, such as temperature or
                            luminance for example, every psychological event can be worked out in the hard physical terms of one
                            scale or another. <a href="#foot-2" class="in-text-foot"><sup class="superscript">2</sup></a> Notably,
                            this would
                            significantly codify and quantify consciousness itself.
                        </p>
                        <p>
                            The aforementioned absolute threshold — sometimes called a limen — has been described as the beginning
                            of conscious experience; this is distinctly gorgeous in a way that is both technical and poetic. Below
                            this
                            threshold is subliminal sensation, above is perception. Each moment, a vast quantity of various energies
                            — heat, radiation, pressure, etc. — interface with the our sensory apparatuses. This energy originates
                            from within and without. Only a subset of this energy triggers sensory events, and only a fraction the
                            data collected in these events will survive subsequent filtration and be injected up, over the
                            threshold, into our stream of consciousness.
                        </p>
                        <p>
                            <span id="foot-3-start"></span>Perhaps consciousness is more than whatever data makes it into
                            perception. Perhaps not — then all we
                            need to recreate human consciousness is the “algorithm” of consciousness, raw supraliminal data as
                            input, and sufficient computing power. But this somehow seems too reductionistic. Indeed, there are deep
                            problems to be solved here, problems divided among various intellectual camps. <a href="#foot-3"
                                class="in-text-foot"><sup class="superscript">3</sup></a>
                        </p>

                        <div class="content-div">
                            <h2 id="wo">Woo: Out of Body</h2>
                        </div>
                        <p>
                            <span id="foot-4-start"></span> Neurologist Olaf Blanke (and colleagues) implanted electrodes in the brain of a
                            patient to evaluate her
                            epileptic attacks. While she was fully conscious, these white coats electrically stimulated her
                            brain at
                            the angular gyrus region. Twice, perceptual alterations in physical position followed:
                            weightlessness, a
                            specific feeling that she was hovering above her bed. Findings were published in Nature (2002).<a
                                href="#foot-4" class="in-text-foot"><sup class="superscript">4</sup></a>
                        </p>
                        <p>
                            What shall we do with this out-of-body experience, veritably amped out of a patient, twice?
                            Categorically, it doesn't seem to fit into any major religious tradition. <span
                                id="foot-5-start"></span>The closest thing is perhaps astral
                            projection, an intentional out-of-body experience used to survey the universe.<a href="#foot-5"
                                class="in-text-foot"><sup class="superscript">5</sup></a> In contrast, the idea of
                            physical death within Christianity is thought to be a semi-permanent separation of the body and spirit
                            because the body died and lost all neurological activity. In connection, it would be altogether mistaken
                            to suggest that this experience implies an immaterial aspect of the person, such as a soul or spirit.
                            Rather this out-of-body experience is bound to matter and determined by matter. <span
                                id="foot-6-start"></span> (As such, it lends
                            credence to the physicalist theories of consciousness not the dualist theories of the same, panpsychism
                            notwithstanding.<a href="#foot-6" class="in-text-foot"><sup class="superscript">6</sup></a>)
                        </p>
                        <p>
                            One possibility is that we are all having out-of-body experiences all the time, just subliminally and in
                            various degrees. As woo-woo as this might sound, if increased stimulation of the angular gyrus region
                            produced a conscious out-of-body experience, then is it not possible that the out-of-body experience was
                            already subliminally present and active? Might this help explain other weird experiences, such as
                            instinctually locating — and making eye-contact with — that one rando staring at you in large crowd of
                            strangers. In other words, the subliminal out-of-body aspect of your awareness independently located the
                            person before
                            “you” did, and directed your gaze to them without making itself known? Or how about the experience of
                            thinking of someone moments before they surprise you with a call or text? This raises the question of
                            physic reach: what kind of spacial distance can the subliminal out-of-body aspect of your awareness
                            survey. Naturally, at some point this just becomes a kind of subliminal astral projection. How many
                            other types of subliminal awarenesses, for lack of a better term, might we have but can't yet explain,
                            much less explain with physics? Also, will artificial general intelligence have anything like subliminal
                            sensation, or is this, by definition, impossible?
                        </p>

                        <div class="content-div">
                            <h2 id="philosophy">Philosophy: Theory of Mind</h2>
                        </div>
                        <p>
                            <span id="foot-7-start"></span>In philosophy (and psychology), theory of mind describes the way one mind
                            understands or conceives of
                            another mind. To give you an idea, there are two common positions regarding theory of mind:
                            theory-theory and simulation.
                            <a href="#foot-7" class="in-text-foot"><sup class="superscript">7</sup></a>
                            <sup class="sup-comma-break-single">,</sup>
                            <a href="#foot-8" class="in-text-foot"><sup class="superscript">8</sup></a> Theory-theory says that
                            theory is the basic medium of
                            consciousness — we
                            literally theorize the minds of other people. The less literal simulation position posits that we
                            instead
                            play out a hypothetical other-mind scenario when considering other people. By analogy, where
                            theory-theory imagines and describes someone else's shoes, simulation imagines said shoes and puts them
                            on.
                        </p>
                        <p>
                            <span id="foot-9-start"></span>  Now, theory of mind is necessarily self-reflective because it allows an
                            organism to understand its own
                            mind too. At least one type of octopus (without an official scientific name yet) seems to predict
                            how
                            prey will respond to certain hunting tactics, thereby exhibiting a kind of theory of mind.
                            Biologist Roy Caldwell says this behavior is unique relative to other octopuses. He says, “When this
                            octopus sees a shrimp at a distance, it creeps up . . . touches it on the far side and . . . scares
                            it
                            into its other arms.” Interestingly, this theory-of-mind capable octopus, in between playing
                            tap-on-the-shoulder killer, happens to be extraordinarily gregarious, relative to octopuses in
                            general:
                            does social behavior imply theory of mind? <a href="#foot-9" class="in-text-foot"><sup
                                class="superscript">9</sup></a>
                        </p>
                        <p>
                            <span id="foot-10-start"></span>  Yet, as interesting as this is, there is a paradox whenever we try to say
                            anything about our own unique
                            subjective consciousness. <a href="#foot-10" class="in-text-foot"><sup
                                class="superscript">10</sup></a> A rather crude version of it goes like this: after
                            conceiving of our own
                            consciousness with its specific subjective character, you may then say what it is. If you can say
                            what is,
                            then others can conceive of it too, thereby destroying its unique subjective character — your point
                            of view is not unique if someone else can access it and conceive of it too. But, if you cannot
                            conceive of
                            our own consciousness with a specific subjective character, then it does not exist, by definition.
                            Drolly, octopuses with theory of mind will have this problem too. <span id="foot-11-start"></span>
                            As such, I hypothesize the
                            existence
                            of at least a few existentially angsty cephalopods — I imagine that these little killjoys would want
                            an official
                            scientific name that references Buddha or Sophocles or perhaps even Schopenhauer.<a href="#foot-11"
                                class="in-text-foot"><sup class="superscript">11</sup></a><span id="foot-12-start"></span>That
                            said, their
                            mood
                            may remedied with some MDMA, as a team of neuroscientists demonstrated on octopuses, albeit
                            of
                            a different species (<span class="italic">Octopus bimaculoides</span>). <a href="#foot-12"
                                class="in-text-foot"><sup class="superscript">12</sup></a><sup class="sup-comma-break-double">,</sup> <a href="#foot-13"
                                    class="in-text-foot"><sup class="superscript">13</sup></a>
                        </p>
                    </div>

                    <div class="content-div">
                        <h3 id="footnotes">Footnotes</h3>
                        <div class="content-div-footnotes">
                            <p id="foot-1"><a href="#foot-1-start"><span class="foot-number">1.</span></a>{" "}
                                Haber, N., Hershenson, M.
                                <a href="https://a.co/d/hnM6uZa" target="_blank" rel="noopener noreferrer"><span class="italic">The
                                    Psychology of Visual Perception</span></a>.
                                New York:
                                Holt, Rinehart, and Winston, Inc.,
                                1973.{" "}
                                <a href="#foot-1-start">⬆️</a>
                            </p>
                            <p id="foot-2"><a href="#foot-2-start"><span class="foot-number">2.</span></a>{" "}
                                Increasing in precision, we have four scales of measurement: nominal, ordinal, interval, and ratio.
                                Nominal: all elements are equal and fungible. Ordinal: elements have a comparative magnitudes that
                                establish a hierarchy and may be ordered accordingly. Interval: an ordinal scale with a unit of
                                measurement — requires the equality of intervals (units) but does not require a zero point. Ratio:
                                an interval scale with equality of ratios of values and intervals, implies a zero point.{" "}
                                <a href="#foot-2-start">⬆️</a>
                            </p>
                            <p id="foot-3"><a href="#foot-3-start"><span class="foot-number">3.</span></a>{" "}
                                See:
                                Stuart Russell's book <a href="https://a.co/d/2cec4C2" target="_blank"
                                    rel="noopener noreferrer"><span class="italic">Human
                                        Compatible</span></a>;
                                Nick Bostrom's book <a href="https://a.co/d/91LsrpN" target="_blank" rel="noopener noreferrer"><span
                                    class="italic">Superintelligence</span></a>;
                                Max Tegmark's book <a href="https://a.co/d/4OafBcq" target="_blank" rel="noopener noreferrer"><span
                                    class="italic">Life 3.0</span></a>;
                                Eliezer Yudkowsky's article "<a href="https://intelligence.org/files/LOGI.pdf" target="_blank"
                                    rel="noopener noreferrer">Levels of
                                    Organization in General Intelligence</a>".{" "}
                                <a href="#foot-3-start">⬆️</a>
                            </p>
                            <p id="foot-4"><a href="#foot-4-start"><span class="foot-number">4.</span></a>{" "}
                                Blanke, O., et al. "<a href="https://pubmed.ncbi.nlm.nih.gov/12239558/" target="_blank"
                                    rel="noopener noreferrer">Stimulating illusory own-body perceptions.</a>" Nature. 2002 Sep
                                19;419(6904):269-70. doi: 10.1038/419269a. PMID: 12239558.{" "}
                                <a href="#foot-4-start">⬆️</a>
                            </p>
                            <p id="foot-5"><a href="#foot-5-start"><span class="foot-number">5.</span></a>{" "}
                                Wikipedia, "<a href="https://en.wikipedia.org/wiki/Astral_projection" target="_blank"
                                    rel="noopener noreferrer">Astral projection</a>."{" "}
                                <a href="#foot-5-start">⬆️</a>
                            </p>
                            <p id="foot-6"><a href="#foot-6-start"><span class="foot-number">6.</span></a>{" "}
                                Stanford Encyclopedia of Philosophy, "<a
                                    href="https://plato.stanford.edu/entries/consciousness/#MetTheCon" target="_blank"
                                    rel="noopener noreferrer">Consciousness</a>."{" "}
                                <a href="#foot-6-start">⬆️</a>
                            </p>
                            <p id="foot-7"><a href="#foot-7-start"><span class="foot-number">7.</span></a>{" "}
                                Blackburn, S.
                                <a href="https://a.co/d/92N7ipG" target="_blank" rel="noopener noreferrer"><span class="italic">The
                                    Oxford Dictionary of Philosophy</span></a>.
                                New York:
                                Oxford University Press
                                1994.{" "}
                                <a href="#foot-7-start">⬆️</a>
                            </p>
                            <p id="foot-8"><a href="#foot-7-start"><span class="foot-number">8.</span></a>{" "}
                                Wikipedia, "<a href="https://en.wikipedia.org/wiki/Theory_of_mind" target="_blank"
                                    rel="noopener noreferrer">Theory of Mind</a>." See heading
                                "Philosophical and psychological roots."{" "}
                                <a href="#foot-7-start">⬆️</a>
                            </p>
                            <p id="foot-9"><a href="#foot-9-start"><span class="foot-number">9.</span></a>{" "}
                                Sanders, R. "<a
                                    href="https://news.berkeley.edu/2015/08/12/octopus-shows-unique-hunting-social-and-sexual-behavior/"
                                    target="_blank" rel="noopener noreferrer">Octopus Shows Unique Hunting
                                    Social and Sexual Behavior</a>." UC Berkeley News.{" "}
                                <a href="#foot-9-start">⬆️</a>
                            </p>
                            <p id="foot-10"><a href="#foot-10-start"><span class="foot-number">10.</span></a>{" "}
                                There is a roughly parallel argument involving an idea of a private language. See: Kripke, S. <a
                                    href="https://a.co/d/aiuXjUS" target="_blank" rel="noopener noreferrer"><span
                                        class="italic">Wittgenstein on Rules and Private Language: An Elementary
                                        Exposition</span></a> (1982); Wittgenstein, L., <a href="https://a.co/d/1atvPiU"
                                            target="_blank" rel="noopener noreferrer"><span class="italic">Philosophical
                                                Investigations</span></a>; Stanford Encyclopedia of Philosophy, "<a
                                                    href="https://plato.stanford.edu/entries/private-language/" target="_blank"
                                                    rel="noopener noreferrer">Private Language</a>."{" "}
                                <a href="#foot-10-start">⬆️</a>
                            </p>
                            <p id="foot-11"><a href="#foot-11-start"><span class="foot-number">11.</span></a>{" "}
                                The slant here is due to the fact that Buddha and Schopenhauer were both pessimists of one kind or
                                another. Meanwhile, Sophocles expressed pessimism memorably, “Not to be born is best, but having
                                seen the light, the next best is to go whence one came as soon as may be” (Oedipus at Colonus).{" "}
                                <a href="#foot-11-start">⬆️</a>
                            </p>
                            <p id="foot-12"><a href="#foot-12-start"><span class="foot-number">12.</span></a>{" "}
                                Edsinger, E., Dölen, G., "<a href="https://doi.org/10.1016/j.cub.2018.07.061" target="_blank"
                                    rel="noopener noreferrer">A Conserved Role for Serotonergic Neurotransmission in Mediating
                                    Social Behavior in Octopus</a>." Current Biology 28, no. 19 (2018): 3136-3142.{" "}
                                <a href="#foot-12-start">⬆️</a>
                            </p>
                            <p id="foot-13"><a href="#foot-12-start"><span class="foot-number">13.</span></a>{" "}
                                The Tim Ferris Show (Podcast). TWO LINKS: <a
                                    href="https://www.youtube.com/watch?v=Pt9_E4ZR5S0&t=2761s" target="_blank"
                                    rel="noopener noreferrer">Link to YouTube video location here</a>; Link to Tim's podcast page
                                here:
                                <a href="https://tim.blog/2023/04/19/gul-dolen/" target="_blank" rel="noopener noreferrer"> Dr. Gül
                                    Dölen on Rethinking Psychedelics, New Applications (Autism, Stroke, and Allergies), The
                                    Neurobiology of Beginner's Mind, Octopuses on MDMA, and The Master Key of Metaplasticity
                                    #667</a>."{" "}
                                <a href="#foot-12-start">⬆️</a>
                            </p>
                        </div>
                    </div>
                    <div class="content-div">
            <h1>Science, Woo, and Philosophy: Signal Detection, Radical Mystery, and the External World</h1>
            <div class="content-div">
                <span class="date">
                    March 24, 2024
                </span>
                <span class="separator">
                    |
                </span>
                <a class="author" href="https://stephenjurgensen.com" title="Stephen Jurgensen">
                    stephenjurgensen.com
                </a>
            </div>
            <div class="content-div">
                <p>Consider the following:</p>
                <ul>
                    <li>
                        Signal Detection Theory.
                    </li>
                    <li>
                        The Radical Mystery of Consciousness.
                    </li>
                    <li>
                        The Problem of the External World.
                    </li>
                </ul>
            </div>
            <div class="content-div">
                <h2 id=""> Science: Signal Detection Theory </h2>
            </div>
            <p>
                The meaning of life determines the bounds and varieties of human experience — the inverse of what was
                suggested in{" "}
                <a href="#march22_2024">
                    my last newsletter</a>,{" "}
                namely that the bounds and varieties of human experience constitute the meaning of life. So, which is
                it?
            </p>
            <p>
                My previous treatment of the method of limits, which is used to find the absolute and difference
                thresholds, suggested a bottom-up definition of perception. On the “bottom,” miscellaneous inbound
                energy passes into our sensory apparatuses as a signal, which then gets organized, interpreted, and
                formed into our stream of consciousness. The early methods tried to account for both psychology and
                physics with a single threshold. In any case, an apparent problem here is the
                mysterious algorithm — a kind of black box — by which the brain “organizes and interprets and forms.”
                The “black box” separates appearance (us) from reality (energy and matter).
            </p>
            <p>
                <span id="foot-1-1-start"></span> Signal-detection theory provides us with a framework for a top-down
                approach to perception. It discards the method of limits on the ground that thresholds don't exist.
                <a href="#foot-1-1" class="in-text-foot"><sup class="superscript">1</sup></a>
                <sup class="sup-comma-break-single">,</sup>
                <a href="#foot-1-2" class="in-text-foot"><sup class="superscript">2</sup></a>
                Roughly speaking, signal-detection theory relates perception and sensation independently — issues of
                psychology and physics may be held separately. 
                <span id="foot-1-3-start"></span>{" "}
                Experimental data independently determines, on the one
                hand, a person's sensitivity relative to background noise, and, on the other hand, their response
                strategy (i.e., their decision process, e.g., “Did I see something there or not?”).
                <a href="#foot-1-3" class="in-text-foot"><sup class="superscript">3</sup></a>{" "}
                And what is a response strategy but a manifestation of underlying and competing features of
                consciousness? Here, psychologists refer to something called a “perceptual set,” which is built on
                preexisting “schemas.” In this way, there must be an ultimate or topmost schema at work; it dynamically
                determines your perceptual set, which, in turn, is used to make sense of the distal stimuli in your
                heart, cochlea, retinas, lips, fingers, toes, etc.
            </p>
            <p>
                But what decides the topmost schema, that is, what decides consciousness?
            </p>
            <p>
                You see, if consciousness selects data, but data in turn affects consciousness, then we may well have a
                loop. Where is ground? It appears that without an independent, perhaps external, topmost source of
                truth, perception is delusion. But what could that final source be? In considering the implications
                here, we cross
                over into some woo-woo territory.
            </p>

            <div class="content-div">
                <h2 id="wo">Woo: Radical Mystery</h2>
            </div>
            <p>
                <span id="foot-1-4-start"></span>
                The polymath{" "}
                <a href="https://en.wikipedia.org/wiki/J._B._S._Haldane" target="_blank" rel="noopener noreferrer">
                    J. B. S. Haldane</a>{" "}
                said, “The universe is not only queerer than we imagine but queerer than we can imagine.”
                <a href="#foot-1-4" class="in-text-foot"><sup class="superscript">4</sup></a>
                <span id="foot-1-5-start"></span>
                Rather starkly, this is another form of that aforementioned distinction between appearance and reality —
                this distinction goes back at
                least as far as Parmenides (born c. 515 BC).
                <a href="#foot-1-5" class="in-text-foot"><sup class="superscript">5</sup></a>
                Put differently, if reality is truly outside of our ability to imagine it, then how do we reach out and
                connect with it?
                This is the problem of the external world. If we are fundamentally removed from the external world, how
                do we even speak about it? What does the word “reality” stand for?
            </p>
            <p>
                <span id="foot-1-6-start"></span>
                Abetted by our heading, I'd like to evoke a kind of radical mystery about everyday existence, rather
                than just talk about it in objective terms (if that is even possible).
                <a href="#foot-1-6" class="in-text-foot"><sup class="superscript">6</sup></a>
                It's notable that people who seriously question their reality, and Reality generally, are normally
                classified as insane. But the fact is, neither top-down nor bottom-up theories of perception support a
                consciousness grounded in direct reality. The material reductionism of bottom-up perception implies that
                you are a superfluous self-awareness with a veil between you and reality — you are, so to speak,
                buffered out and away from reality, a passive spectator of “life.”{" "}
                <span id="foot-1-7-start"></span>
                The ungroundedness of top-down
                perception implies that you are pure subjectivity synthesized from its own products of subjectivity, ad
                infinitum — you are, so to speak, irreparably open to an infinite regress of higher and higher
                “ultimate”
                sources of meaning. Pascal captured the feeling: “The eternal silence of these infinite spaces fills me
                with
                dread.”
                <a href="#foot-1-7" class="in-text-foot"><sup class="superscript">7</sup></a>
            </p>
            <p>
                <span id="foot-1-8-start"></span>
                When the philosopher Thomas Nagel turned to the subject of consciousness and mind, he wrote, “. . . The
                world is a strange place, and nothing but radical speculation gives us hope of coming up with any
                candidates for the truth.”
                <a href="#foot-1-8" class="in-text-foot"><sup class="superscript">8</sup></a>
            </p>

            <div class="content-div">
                <h2 id="philosophy">Philosophy: The External World</h2>
            </div>
            <p>
                <span id="foot-1-9-start"></span>
                Here is a cursory selection
                <a href="#foot-1-9" class="in-text-foot"><sup class="superscript">9</sup></a>
                of what a few philosophers think:
            </p>
            <p>
                <span id="foot-1-10-start"></span>
                The skeptic Sextus Empiricus (fl. c. AD 200) suggests that the ideas (signs) generated by consciousness
                can only ever be about other ideas, thereby condemning humans to an inescapable world of <span style={{"display": "inline-block"}}>appearances.
                <a href="#foot-1-10" class="in-text-foot"><sup class="superscript">10</sup></a></span>
                Although reality exists, it is, outside of practical purposes, unknowable. The benefit of such
                skepticism is that we can remain undisturbed by not holding onto beliefs. This virtuous imperturbability
                is called ataraxia.
            </p>
            <p>
                <span id="foot-1-11-start"></span>
                The idealist George Berkeley (1685-1753) essentially denied the existence of the distinction between
                reality and appearances. For him, the so-called external world did not exist. His radical immaterialism
                claimed that all that exists is a world of minds together with their 
                <span style={{"display": "inline-block"}}>
                ideas.
                    <a href="#foot-1-11" class="in-text-foot"><sup class="superscript">11</sup></a>
                </span>
            </p>
            <p>
                <span id="foot-1-12-start"></span>
                The Cartesian dualist René Descartes (1596-1650) took mind and matter as separate substances, both real,
                whose relationship (e.g., knowledge about reality) must be certified by divine revelation. But getting
                over the divide need not involve God, or even a weird shamanistic voyage with maidens and a chariot, as
                with {" "}
                <span style={{"display": "inline-block"}}>
                    Parmenides.
                <a href="#foot-1-12" class="in-text-foot"><sup class="superscript">12</sup></a>
                </span>
            </p>
            <p>
                <span id="foot-1-13-start"></span>
                The anti-empiricist, rationalist Noam Chomsky (1928- ) thinks that humans are born with an innate
                universal grammar that allows children to learn language at an otherwise inexplicable rate.
                <a href="#foot-1-13" class="in-text-foot"><sup class="superscript">13</sup></a>
                This implies that there is some <span class="italic"> a priori </span> basis for human knowledge pre-formed in the human brain, which
                in turn means humans cross the divide between appearances and reality using <span class="italic"> a priori </span> insight (i.e.,
                rationalism).{" "}
                <span id="foot-1-14-start"></span>
                An early rationalist, Pythagoras (b. c. 570 BC), thought mathematics was the only point of entry to
                obtain truths about {" "}
                <span style={{"display": "inline-block"}}>
                    reality.
                <a href="#foot-1-14" class="in-text-foot"><sup class="superscript">14</sup></a>
                </span>
            </p>
            <p>
                So much for a few examples. How about our initial question of whether experience or meaning takes
                priority? Primarily: is our experience framed by and subjugated to a source of ultimate meaning? — or is
                our meaning a derivative consequence of our experiences? What is curious here is that no matter how you
                answer, you are still confronted with how your answer relates to the problem of the external world. In
                particular, you have to speculate about something seemingly obvious and direct, the external world — and
                that unavoidable position of speculation is what is truly curious.
            </p>
        </div>

        <div class="content-div">
            <h3 id="footnotes">Footnotes</h3>
            <div class="content-div-footnotes">
                <p id="foot-1-1"><a href="#foot-1-1-start"><span class="foot-number">1.</span></a>{" "}
                    This is actually consistent with the later experimental findings under the method of limits. For example,
                    the absolute threshold must be statistically calculated because of variables on both the
                    psychological and physical fronts. Experimental equipment will have some degree of inaccuracy and
                    precision tolerance. And motivation, caffeination, and attention affect a person's perception of
                    many
                    things, including abjectly minimal stimuli.{" "}
                    <a href="#foot-1-1-start">⬆️</a>
                </p>
                <p id="foot-1-2"><a href="#foot-1-1-start"><span class="foot-number">2.</span></a>{" "}
                    “The impact here appears to be more than methodological. . . . The perceiver can always gain more
                    information by saying "yes" if he relaxes his [decision strategy]. Thus, the basic concept of
                    threshold is discarded as being misleading.” Haber, N., Hershenson, M.
                    <a href="https://a.co/d/hnM6uZa" target="_blank" rel="noopener noreferrer"><span class="italic">The
                            Psychology of Visual Perception</span></a>.
                    New York:
                    Holt, Rinehart, and Winston, Inc.,
                    1973.{" "}
                    <a href="#foot-1-1-start">⬆️</a>
                </p>
                <p id="foot-3"><a href="#foot-1-3-start"><span class="foot-number">3.</span></a>{" "}
                    Two experimentally derived variables — the false alarm and hit rate — tell us your decision
                    strategy. Meanwhile, the degree of separation between two response curves — noise and
                    noise-plus-signal — independently establishes your objective sensitivity.{" "}
                    <a href="#foot-1-3-start">⬆️</a>
                </p>
                <p id="foot-4"><a href="#foot-1-4-start"><span class="foot-number">4.</span></a>{" "}
                    As quoted in: Sacks, Oliver. 1995.
                    <a href="https://a.co/d/0B2mNvG" target="_blank" rel="noopener noreferrer">
                        An Anthropologist on Mars: Seven Paradoxical Tales.
                    </a>
                    Knopf.{" "}
                    <a href="#foot-1-4-start">⬆️</a>
                </p>
                <p id="foot-1-5"><a href="#foot-1-5-start"><span class="foot-number">5.</span></a>{" "}
                    Moore, A. W.
                    <a href="https://a.co/d/aQCQD3z" target="_blank" rel="noopener noreferrer">
                        The Infinite. The Problems of Philosophy.
                    </a>
                    Routledge, 1990.{" "}
                    <a href="#foot-1-5-start">⬆️</a>
                </p>
                <p id="foot-1-6"><a href="#foot-1-6-start"><span class="foot-number">6.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href="https://plato.stanford.edu/entries/perception-episprob/" target="_blank"
                        rel="noopener noreferrer">Problem of the External World</a>"{" "}
                    <a href="#foot-1-6-start">⬆️</a>
                </p>
                <p id="foot-1-7"><a href="#foot-1-7-start"><span class="foot-number">7.</span></a>{" "}
                    For a mind-bending experience with Phil Ford and J.F. Martel, listen to
                    <a href="https://www.weirdstudies.com/120" target="_blank" rel="noopener noreferrer">
                        episode 120
                    </a>
                    of Weird Studies.{" "}
                    <a href="#foot-1-7-start">⬆️</a>
                </p>
                <p id="foot-1-8"><a href="#foot-1-8-start"><span class="foot-number">8.</span></a>{" "}
                    Moore, A. W.
                    <a href="https://a.co/d/aQCQD3z" target="_blank" rel="noopener noreferrer">
                        The Infinite. The Problems of Philosophy.
                    </a>
                    Routledge, 1990.{" "}
                    <a href="#foot-1-8-start">⬆️</a>
                </p>
                <p id="foot-1-9"><a href="#foot-1-9-start"><span class="foot-number">9.</span></a>{" "}
                    References for the following sketches: Blackburn, S.The Oxford Dictionary of Philosophy. New York:
                    Oxford University Press 1994. And Kirk, G. S. 1957. The Presocratic Philosophers: A Critical History
                    with a Selection of Texts. Edited by J. E. Raven. University Press.{" "}
                    <a href="#foot-8-start">⬆️</a>
                </p>
                <p id="foot-1-10"><a href="#foot-1-10-start"><span class="foot-number">10.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href="https://plato.stanford.edu/entries/sextus-empiricus/" target="_blank"
                        rel="noopener noreferrer">Sextus Empiricus</a>"{" "}
                    <a href="#foot-1-10-start">⬆️</a>
                </p>
                <p id="foot-1-11"><a href="#foot-1-11-start"><span class="foot-number">11.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href=" https://plato.stanford.edu/entries/berkeley/" target="_blank"
                        rel="noopener noreferrer">Berkeley</a>"{" "}
                    <a href="#foot-1-11-start">⬆️</a>
                </p>
                <p id="foot-1-12"><a href="#foot-1-12-start"><span class="foot-number">12.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href="https://plato.stanford.edu/entries/descartes/" target="_blank"
                        rel="noopener noreferrer">Descartes</a>"{" "}
                    <a href="#foot-1-12-start">⬆️</a>
                </p>
                <p id="foot-1-13"><a href="#foot-1-13-start"><span class="foot-number">13.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href=" https://plato.stanford.edu/entries/innateness-language/" target="_blank"
                        rel="noopener noreferrer">Innateness Language</a>"{" "}
                    <a href="#foot-1-13-start">⬆️</a>
                </p>
                <p id="foot-14"><a href="#foot-1-14-start"><span class="foot-number">14.</span></a>{" "}
                    Stanford Encyclopedia of Philosophy, "
                    <a href="https://plato.stanford.edu/entries/pythagoras/#WasPytMatCos" target="_blank"
                        rel="noopener noreferrer">Pythagoras</a>"{" "}
                    <a href="#foot-1-14-start">⬆️</a>
                </p>
            </div>
        </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home